import React, {useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import {useLocation} from "react-router";
import {Footer} from "../components/Footer";

const WithBackground = () => {
    return (
        <div className="h-full flex flex-col">
            <div className={`h-full bg-image`} style={{backgroundImage: 'url("/background.jpg")'}}>
                <div className="sm:mt-20 sm:shadow-md sm:mx-auto sm:w-full sm:max-w-[480px] overflow-y-auto">
                    <div className="relative bg-white px-12 pb-6 pt-4 sm:rounded-lg sm:px-8">
                        <Link to="/sign-in">
                            <img className="mx-auto sm:mx-0 h-10 w-auto" src="/logo.svg" alt="logo"/>
                        </Link>
                        <div className="mx-auto sm:mx-0 h-10 w-auto flex justify-center">
                            <span className="text-blue-400 font-bold">
                                voor tijdsefficiëntie, veilige en effectieve communicatie!
                            </span>
                        </div>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100 py-4 text-center text-sm">
                <p>
                    geeftantwoord.be © 2025 -
                    <ActionLink to="https://www.cybersecured.be/geeftantwoord-contactformulier">
                        <span className="underline text-gray-900 hover:text-gray-500">contact</span>
                    </ActionLink>
                </p>
            </div>
        </div>
    );
};


export function Layout({children, title}) {
    return (<>
        <h2 className="mb-2 mt-4 text-center sm:text-left leading-9 font-bold tracking-tight text-3xl text-gray-700">
            {title}
        </h2>
        {children}
    </>);
}

export function FormInput({
                              id,
                              type,
                              placeholder,
                              value,
                              onChange,
                              autoComplete,
                              disabled,
                              isTopRounded,
                              isBottomRounded
                          }) {
    const classes = `
        relative block w-full ${isTopRounded ? 'rounded-t-md' : ''} ${isBottomRounded ? 'rounded-b-md' : ''} 
        border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 
        focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6
        ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
    `;
    return (<input
        value={value}
        onChange={onChange}
        disabled={disabled}
        id={id}
        name={id}
        type={type}
        autoComplete={autoComplete}
        required
        className={classes}
        placeholder={placeholder}
    />);
}

function generateDropdownOptions(start, end) {
    return Array.from({length: end - start + 1}, (_, i) => (i + start).toString().padStart(2, '0'));
}

export function BirthdaySelector({
                                     dayValue,
                                     monthValue,
                                     yearValue,
                                     onDayChange,
                                     onMonthChange,
                                     onYearChange,
                                     isTopRounded,
                                     isBottomRounded
                                 }) {
    const [isDayFocused, setDayFocused] = useState(false);
    const [isMonthFocused, setMonthFocused] = useState(false);
    const [isYearFocused, setYearFocused] = useState(false);

    const commonClasses = `
        block w-full border-0 p-0 placeholder-gray-400 
        focus:ring-0 sm:text-sm sm:leading-6
    `;

    const currentYear = new Date().getFullYear();
    const days = generateDropdownOptions(1, 31);
    const months = generateDropdownOptions(1, 12);
    const years = generateDropdownOptions(currentYear - 100, currentYear);

    return (
        <div className={`px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 
            ${isTopRounded ? 'rounded-t-md' : ''} ${isBottomRounded ? 'rounded-b-md' : ''}`}>
            <label htmlFor="birthday" className="block text-xs font-medium text-gray-400">
                Geboortedatum
            </label>
            <div className="flex">
                <select
                    value={dayValue}
                    required={true}
                    onChange={onDayChange}
                    onFocus={() => setDayFocused(true)}
                    onBlur={() => setDayFocused(false)}
                    className={`${commonClasses} rounded-l-md ${isDayFocused ? 'text-blue-500' : dayValue ? 'text-black' : 'text-gray-400'}`}
                >
                    <option disabled value="" hidden={!dayValue}>Dag</option>
                    {days.map(day => <option key={day} value={day}>{day}</option>)}
                </select>
                <select
                    value={monthValue}
                    required={true}
                    onChange={onMonthChange}
                    onFocus={() => setMonthFocused(true)}
                    onBlur={() => setMonthFocused(false)}
                    className={`${commonClasses} ${isMonthFocused ? 'text-blue-500' : monthValue ? 'text-black' : 'text-gray-400'}`}
                >
                    <option disabled value="" hidden={!monthValue}>Maand</option>
                    {months.map(month => <option key={month} value={month}>{month}</option>)}
                </select>
                <select
                    value={yearValue}
                    required={true}
                    onChange={onYearChange}
                    onFocus={() => setYearFocused(true)}
                    onBlur={() => setYearFocused(false)}
                    className={`${commonClasses} rounded-r-md ${isYearFocused ? 'text-blue-500' : yearValue ? 'text-black' : 'text-gray-400'}`}
                >
                    <option disabled value="" hidden={!yearValue}>Jaar</option>
                    {years.map(year => <option key={year} value={year}>{year}</option>)}
                </select>
            </div>
        </div>
    );
}

export function ActionButton({loading: disabled, children, onClick}) {
    const classes = `
        flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold leading-6 text-white 
        shadow-sm ${disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-500"} focus-visible:outline 
        focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400`;
    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    };
    return <button onClick={handleClick} disabled={disabled} type="submit" className={classes}>{children}</button>;
}

export function ActionLink({loading, to, children, state, onClick}) {
    return (<Link
        to={to}
        state={state}
        onClick={onClick}>
        <span className={loading ? "opacity-50 cursor-not-allowed" : ""}>
            {children}
        </span>
    </Link>);
}

export function RedirectActionLink({loading, to, children, state, onClick}) {
    const location = useLocation();
    const originalFrom = location.state?.from;
    const combinedState = {...state, from: originalFrom};
    return <ActionLink to={to} loading={loading} state={combinedState} onClick={onClick}>
        {children}
    </ActionLink>
}


export default WithBackground;